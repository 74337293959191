import styled from "styled-components";

export const SectionTitle = styled.div`
  grid-column: 1 / -1;
  font-family: ${(props) => props.theme.fonts.mono};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 1.267;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.color};
`;
