import styled from "styled-components";

export const Button = styled.button`
  grid-column: ${(props) => props.grid};
  background: none;
  color: ${(props) => props.theme.colors.color};
  border: 1px solid ${(props) => props.theme.colors.color};
  border-radius: 100%;
  padding: 14px 52px;
  font-family: ${(props) => props.theme.fonts.mono};
  text-transform: uppercase;
  font-family: 15px;
  line-height: 1.26;
  font-weight: 400;
  transition: background 0.25s ease-in-out, color 0.25s ease-in-out;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.25 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  &:hover {
    background: ${(props) => props.theme.colors.color};
    color: ${(props) => props.theme.colors.bg};
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;

    &:hover {
      background: ${(props) => props.theme.colors.bg};
      color: ${(props) => props.theme.colors.color};
    }
  }
`;
