import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactPlayer from "react-player";
import { useDraggable } from "react-use-draggable-scroll";
import { AnimatePresence, motion, useScroll } from "framer-motion";

import { Caption, Heading3 } from "../../ui/Typography";
import { defaultTransition } from "../../utils/transition";
import { useIsMobile } from "../../hooks/useMediaQuery";

const Wrapper = styled.div`
  grid-column: 1 / -1;
  width: 100%;
  padding: 10px 0 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.color};

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 10px 0;
  }
`;

const Carousel = styled(motion.div)`
  position: relative;
  grid-column: 1 / -1;
  overflow: hidden;
`;

const Scrolling = styled(motion.div)`
  width: 100%;
  height: 100%;
  padding: 6px 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  gap: 10px;
  overflow-x: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  transition: width 0.65s ease, border-radius 0.65s ease;
  /* cursor: grab; */
`;

const PrevBtn = styled(motion.div)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 16px 15px;
  background: ${(props) => props.theme.colors.bg};
  border-radius: 100%;
  z-index: 99;

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 11px 11px;
    svg {
      height: 16px;
      width: auto;
    }
  }
`;

const NextBtn = styled(motion.div)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 16px 15px;
  background: ${(props) => props.theme.colors.bg};
  border-radius: 100%;
  z-index: 99;

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 11px 11px;
    svg {
      height: 16px;
      width: auto;
    }
  }
`;

const MediaWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  transition: width 0.65s ease, border-radius 0.65s ease;
  & > div {
    height: 408px !important;
  }
  & > div > div > div {
    width: 100%;
    height: 100%;
    padding: 0 !important;
  }
  iframe {
    width: 177.77777778vh !important;
    height: 100% !important;
    min-width: 100% !important;
    min-height: 100% !important;
    position: relative !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    pointer-events: none !important;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    & > div {
      height: 246px !important;
    }
  }
`;

const CarouselItemWrapper = styled.div`
  width: calc(((100% - (10px * 11)) / 12) * 4 + (10px * 3));
  /* height: 100%; */
  flex-shrink: 0;
  transition: width 0.65s ease, border-radius 0.65s ease;
  cursor: pointer;

  position: relative;
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    margin: 0 -5px 0 0;
    border-right: 1px solid ${(props) => props.theme.colors.color};
  }

  @media (hover: hover) {
    &:hover {
      width: calc(((100% - (10px * 11)) / 12) * 5 + (10px * 4));
      ${MediaWrapper} {
        border-radius: 100%;
      }
    }
  }

  &:nth-last-child(1) {
    &:after {
      border-right: none;
    }
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    width: calc(((100% - (10px * 5)) / 6) * 4 + (10px * 3));
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  padding-right: 30%;
  padding-bottom: 10px;

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding-right: 0;
  }
`;

const CarouselItem = ({ item }) => {
  //console.log(item?.mainMedia[0]?._type === "img");
  return (
    <CarouselItemWrapper>
      <Link to={`/work/${item.slug.current}`}>
        <MediaWrapper>
          {item?.mainMedia[0]?._type === "img" ? (
            <GatsbyImage
              image={item?.mainMedia[0]?.asset?.gatsbyImageData}
              alt={item?.mainMedia[0]?.alt}
              // layout="fullWidth"
              draggable={false}
            />
          ) : (
            <ReactPlayer
              url={item.mainMedia[0].vimeoURL}
              playing={item.mainMedia[0].autoplay}
              loop={item.mainMedia[0].loop}
              muted={item.mainMedia[0].muted}
              controls={item.mainMedia[0].controls}
              playsinline={item.mainMedia[0].autoplay}
              pip={false}
              width={"100%"}
              height={"auto"}
              config={{
                vimeo: {
                  playerOptions: {
                    byline: false,
                    portrait: false,
                    title: false,
                    responsive: true,
                    color: "EB3329",
                  },
                },
              }}
            />
          )}
        </MediaWrapper>
        <TitleWrapper>
          <Heading3>{item.homeTitle}</Heading3>
        </TitleWrapper>
      </Link>
    </CarouselItemWrapper>
  );
};

export default function Favorites({ data }) {
  const ref = useRef();
  const { events } = useDraggable(ref);
  const [scroll, setScroll] = useState(0);
  const { scrollXProgress } = useScroll({ container: ref });
  const isMobile = useIsMobile();

  useEffect(() => {
    return scrollXProgress.onChange((latest) => {
      setScroll(latest);
    });
  }, []);

  // console.log(scroll);

  const variants = {
    on: {
      opacity: 1,
      transition: {
        ...defaultTransition,
      },
    },
    off: {
      opacity: 0,
      transition: {
        ...defaultTransition,
      },
    },
  };

  return (
    <Wrapper>
      <Caption>Some more of our favourites...</Caption>
      <Carousel variants={variants}>
        <AnimatePresence>
          {scroll > 0 && (
            <PrevBtn
              initial="off"
              animate="on"
              exit="off"
              variants={variants}
              onClick={() =>
                !isMobile
                  ? (ref.current.scrollLeft -= 440)
                  : (ref.current.scrollLeft -= 240)
              }
            >
              <svg
                width="26"
                height="22"
                viewBox="0 0 26 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.301082 10.6655L4.86876e-05 11L0.301082 11.3345L9.30108 21.3345L10.0444 20.6655L1.79541 11.5L25.6727 11.5L25.6727 10.5L1.79541 10.5L10.0444 1.33447L9.30108 0.665509L0.301082 10.6655Z"
                  fill="black"
                />
              </svg>
            </PrevBtn>
          )}
        </AnimatePresence>
        <Carousel>
          <Scrolling {...events} ref={ref}>
            {data.map((item, index) => {
              return <CarouselItem item={item} key={index} />;
            })}
          </Scrolling>
        </Carousel>
        <AnimatePresence>
          {scroll <= 0.9 && (
            <NextBtn
              initial="off"
              animate="on"
              exit="off"
              variants={variants}
              onClick={() =>
                !isMobile
                  ? (ref.current.scrollLeft += 440)
                  : (ref.current.scrollLeft += 240)
              }
            >
              <svg
                width="26"
                height="22"
                viewBox="0 0 26 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.3716 11.3345L25.6727 11L25.3716 10.6655L16.3716 0.665517L15.6284 1.33448L23.8773 10.5H0V11.5H23.8773L15.6284 20.6655L16.3716 21.3345L25.3716 11.3345Z"
                  fill="black"
                />
              </svg>
            </NextBtn>
          )}
        </AnimatePresence>
      </Carousel>
    </Wrapper>
  );
}
