import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { SectionTitle } from "../ui/SectionTitle";
import Intro from "../components/Homepage/Intro";
import WhatWeDo from "../components/Homepage/WhatWeDo";
import Newsletter from "../components/Homepage/Newsletter";
import ProjectPreview from "../components/Homepage/ProjectPreview";
import Transition from "../components/Transition";
import Favorites from "../components/Homepage/Favorites";
import { SEO } from "../components/SEO";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query Home {
      sanityHome {
        id
        intro
        desc
        newsBtn
        newsBtnURL
        newsCTA
        tagline
        headline

        featuredWork {
          title
          homeTitle
          slug {
            current
          }
          mainMedia {
            ... on SanityImg {
              _key
              _type
              alt
              asset {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
              }
            }
            ... on SanityVideo {
              _key
              _type
              alt
              autoplay
              caption
              controls
              loop
              muted
              vimeoURL
            }
          }
        }
        fav {
          title
          homeTitle
          slug {
            current
          }
          mainMedia {
            ... on SanityImg {
              _key
              _type
              alt
              asset {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
              }
            }
            ... on SanityVideo {
              _key
              _type
              alt
              autoplay
              caption
              controls
              loop
              muted
              vimeoURL
            }
          }
        }
      }
      allSanityProject(sort: { fields: orderRank }, limit: 6) {
        edges {
          node {
            id
            mainMedia {
              ... on SanityImg {
                _key
                _type
                alt
                caption
                asset {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
              ... on SanityVideo {
                _key
                _type
                alt
                autoplay
                caption
                controls
                loop
                muted
                vimeoURL
              }
            }
            slug {
              current
            }
            client
            projectCategory {
              title
            }
            homeTitle
          }
        }
      }
    }
  `);
  const projects = data.allSanityProject.edges;

  return (
    <Transition>
      <Intro data={data.sanityHome} />
      <SectionTitle>Featured Work</SectionTitle>
      {data?.sanityHome?.featuredWork?.map((item, index) => {
        return <ProjectPreview data={item} key={index} index={index} />;
      })}
      <WhatWeDo data={data.sanityHome} />
      <Favorites data={data.sanityHome.fav} />
      <Newsletter data={data.sanityHome} />
    </Transition>
  );
};

export default IndexPage;

export const Head = () => <SEO />;
