import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactPlayer from "react-player";

import { Grid } from "../../ui/GridWrapper";
import { Caption, Heading2 } from "../../ui/Typography";
import { motion } from "framer-motion";

const MediaWrapper = styled.div`
  grid-column: 1 / -1;
  padding-bottom: 12px;
  width: 100%;

  overflow-x: hidden;
`;

const TwoUpWrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  gap: 10px;
  & > div {
    height: 594px;
    transition: width 0.65s ease, border-radius 0.65s ease;
    &:nth-child(1) {
      width: calc(((100% - (10px * 11)) / 12) * 4 + (10px * 3));
      overflow: hidden;
    }
    &:nth-child(2) {
      flex: 1;
      overflow: hidden;
    }
    & > div > div > div {
      width: 100%;
      height: 100%;
      padding: 0 !important;
    }
    iframe {
      width: 177.77777778vh !important;
      height: 100% !important;
      min-width: 100% !important;
      min-height: 100% !important;
      position: relative !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      pointer-events: none !important;
    }
    @media ${(props) => props.theme.breakpoints.mobile} {
      height: 246px;
      &:nth-child(1) {
        width: 100%;
      }
      &:nth-child(2) {
        display: none;
      }
    }
  }
`;

const ThreeUpWrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  gap: 10px;
  & > div {
    height: 594px;
    transition: width 0.65s ease, border-radius 0.65s ease;
    &:nth-child(1) {
      width: calc(((100% - (10px * 11)) / 12) * 6 + (10px * 5));
      overflow: hidden;
    }
    &:nth-child(2) {
      width: calc(((100% - (10px * 11)) / 12) * 3 + (10px * 2));
      overflow: hidden;
    }
    &:nth-child(3) {
      width: calc(((100% - (10px * 11)) / 12) * 3 + (10px * 2));
      overflow: hidden;
    }
    & > div > div > div {
      width: 100%;
      height: 100%;
      padding: 0 !important;
    }
    iframe {
      width: 177.77777778vh !important;
      height: 100% !important;
      min-width: 100% !important;
      min-height: 100% !important;
      position: relative !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      pointer-events: none !important;
    }
    @media ${(props) => props.theme.breakpoints.mobile} {
      height: 246px;
      &:nth-child(1) {
        width: 100%;
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        display: none;
      }
    }
  }
`;

const InfoWrapper = styled.div`
  grid-column: 1 / -1;
`;

const Wrapper = styled(motion.div)`
  grid-column: 1 / -1;
  padding: 10px 0 12px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.color};
  @media (hover: hover) {
    &:hover {
      ${MediaWrapper} {
        ${TwoUpWrapper} {
          & > div {
            &:nth-child(1) {
              width: calc(((100% - (10px * 11)) / 12) * 8 + (10px * 7));
              border-radius: 100%;
              overflow: hidden;
            }
            &:nth-child(2) {
              flex: 1;
            }
          }
        }
        ${ThreeUpWrapper} {
          & > div {
            &:nth-child(1) {
              width: calc(((100% - (10px * 11)) / 12) * 3 + (10px * 2));
            }
            &:nth-child(2) {
              width: calc(((100% - (10px * 11)) / 12) * 3 + (10px * 2));
            }
            &:nth-child(3) {
              width: calc(((100% - (10px * 11)) / 12) * 6 + (10px * 5));
              border-radius: 100%;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
`;

const Two = ({ data }) => {
  return (
    <TwoUpWrapper>
      {data?.mainMedia?.map((item, index) => {
        //console.log(item);
        if (item._type === "img") {
          return (
            <GatsbyImage
              image={item?.asset?.gatsbyImageData}
              alt={item?.alt}
              key={item._key}
              index={index}
              layout="fullWidth"
              draggable={false}
            />
          );
        } else {
          return (
            <div>
              <ReactPlayer
                url={item.vimeoURL}
                playing={item.autoplay}
                loop={item.loop}
                muted={item.muted}
                controls={item.controls}
                playsinline={item.autoplay}
                pip={false}
                width="100%"
                height="100%"
                config={{
                  vimeo: {
                    playerOptions: {
                      byline: false,
                      portrait: false,
                      title: false,
                      responsive: true,
                      color: "EB3329",
                    },
                  },
                }}
              />
            </div>
          );
        }
      })}
    </TwoUpWrapper>
  );
};

const Three = ({ data }) => {
  return (
    <ThreeUpWrapper>
      {data?.mainMedia?.map((item, index) => {
        return (
          <GatsbyImage
            image={item?.asset?.gatsbyImageData}
            alt={item.alt}
            key={item._key}
            index={index}
            layout="fullWidth"
            draggable={false}
          />
        );
      })}
    </ThreeUpWrapper>
  );
};

function ProjectPreview({ data }) {
  return (
    <Wrapper grid="1 / -1" padding="10px 0 12px 0" border>
      <Link to={`/work/${data?.slug?.current}`}>
        <Grid>
          <MediaWrapper>
            {data?.mainMedia?.length === 2 ? (
              <Two data={data} />
            ) : (
              <Three data={data} />
            )}
          </MediaWrapper>
          <InfoWrapper>
            <Caption>{data?.client}</Caption>
            <Heading2>{data?.homeTitle}</Heading2>
          </InfoWrapper>
        </Grid>
      </Link>
    </Wrapper>
  );
}

export default ProjectPreview;
