import React from "react";
import styled from "styled-components";
import { useIsMobile } from "../../hooks/useMediaQuery";

import { Grid } from "../../ui/GridWrapper";
import Logo from "../Header/PalLogo.inline.svg";

const Wrapper = styled.div`
  grid-column: 1 / -1;
  padding: 10px 0 245px 0;
  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 0 0 60px 0;
  }
`;

const CopyWrapper = styled.div`
  grid-column: 9 / span 4;
  display: flex;
  flex-direction: row;
  gap: 10px;
  p {
    width: 50%;
    font-family: ${(props) => props.theme.fonts.mono};
    font-size: 15px;
    letter-spacing: 0;
    line-height: 1.267;
    /* text-transform: uppercase; */
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 1/-1;
    flex-direction: column;

    p {
      width: 100%;
      &:nth-child(1) {
        display: none;
      }
    }
  }
`;

function Intro({ data }) {
  const isMobile = useIsMobile();

  return (
    <Wrapper>
      <Grid>
        {!isMobile && <Logo />}
        <CopyWrapper>
          <p>{data.tagline}</p>
          <p>{data.intro}</p>
        </CopyWrapper>
      </Grid>
    </Wrapper>
  );
}

export default Intro;
