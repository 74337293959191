import React from "react";
import styled from "styled-components";
import { Button } from "../../ui/Button";

import { Grid, Wrapper } from "../../ui/GridWrapper";
import { Links } from "../../ui/Links";
import { Caption, Heading2 } from "../../ui/Typography";

const TitleWrapper = styled.div`
  grid-column: 1 / span 6;

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 1/-1;
  }
`;

const ButtonWrapper = styled.div`
  grid-column: 11 / span 2;
  display: flex;
  justify-content: flex-end;

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 1 / span 6;
    width: 100%;
    padding-top: 10px;
  }
`;

function Newsletter({ data }) {
  return (
    <Wrapper padding="10px 0 93px 0">
      <Grid>
        <TitleWrapper>
          <Caption>Subscribe to our newsletter</Caption>
          <Heading2>{data.newsCTA}</Heading2>
        </TitleWrapper>
        <ButtonWrapper>
          <Links
            as="a"
            href={data.newsBtnURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>{data.newsBtn}</Button>
          </Links>
        </ButtonWrapper>
      </Grid>
    </Wrapper>
  );
}

export default Newsletter;
