import React from "react";
import styled from "styled-components";

import { Grid, Wrapper } from "../../ui/GridWrapper";
import { Caption, Heading2, Paragraph } from "../../ui/Typography";

const CTAWrapper = styled.div`
  grid-column: 1 / span 6;

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 1/-1;
    padding-bottom: 10px;
  }
`;
const DescWrapper = styled.div`
  grid-column: 9 / span 4;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  p {
    grid-column: span 2;
    padding-top: 40px;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 1/-1;
    grid-template-columns: repeat(6, 1fr);
    padding-bottom: 60px;
    p {
      grid-column: span 6;
      padding-top: 0;
    }
  }
`;

function WhatWeDo({ data }) {
  return (
    <Wrapper grid="1 / -1" padding="10px 0 134px 0" border>
      <Grid>
        <CTAWrapper>
          <Caption style={{ paddingTop: "4px" }}>What we do...</Caption>
          <Heading2>{data.headline}</Heading2>
        </CTAWrapper>
        <DescWrapper>
          {data.desc.split("\n\n").map((text, index) => {
            return <Paragraph key={index}>{text}</Paragraph>;
          })}
        </DescWrapper>
      </Grid>
    </Wrapper>
  );
}

export default WhatWeDo;
